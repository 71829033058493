// Image
const sizes = {
  '2xs': 6,
  'xs': 10,
  'sm': 16,
  'md': 20,
  'lg': 24,
  'xl': 32,
  '2xl': 64,
  'full': '100%',
};

export const Image = {
  baseStyle: {
    maxWidth: '100%',
  },
  sizes,
  defaultProps: {},
};
