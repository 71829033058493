import { createIcon } from '../createIcon';

export const ArrowBackIcon = createIcon({
  viewBox: '0 0 24 24',
  d: 'M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z',
});

export const ArrowForwardIcon = createIcon({
  viewBox: '0 0 24 24',
  d: 'M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z',
});

export const ArrowUpIcon = createIcon({
  viewBox: '0 0 24 24',
  d: 'M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z',
});

export const ArrowDownIcon = createIcon({
  viewBox: '0 0 24 24',
  d: 'M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z',
});
